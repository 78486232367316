.contact-us-banner-bg{
    /*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground.png");*/
    background-image: url("../../assets/img/ContactUs/contactUsBanner.jpg");
    width: 100%;
    height: 415px;
    background-size: 100% 100%;
}
.map-canvas iframe {
    float: left;
    width: 100%;
    height: 490px;
    border: unset;
}
@media only screen and (max-width: 992px) {
    .contact-us-banner-bg{
        background-image: url("../../assets/img/ContactUs/contactUsBannerMobile.jpg");
        height: 441px;
    }
}

