.about-us-banner-bg{
    /*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground.png");*/
    background-image: url("../../assets/img/about us/visionBanner.jpg");
    width: 100%;
    height: 415px;
    background-size: 100% 100%;
}

@media only screen and (max-width: 992px) {
    .about-us-banner-bg{
        background-image: url("../../assets/img/about us/visionBannerMobileNew.jpg");
        height: 441px;
    }
}

