.featureMenuBar {
    list-style: none;
    position: relative;
    text-align: left;
}

.featureMenuBar li a {
    display: block;
    padding: 6px 20px 6px 20px;
}

.featureMenuBar {
    position: absolute;
    z-index: 200;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 7px -4px 6px rgba(0, 0, 0, 0.05);
    width: 223px;
    border-radius: 5px;
    margin-top:7px;
    margin-left: -12px;
    padding:0;
    background: white;
    transition: transform .20s;
}

.featureMenuBar li {
    float: none;
    font-size:16px;
    font-family: Barlow;
    font-weight:400;
    background: white;
    border-radius: 5px;
    color:#0b407d
}

.featureMenuBar li:hover > a{
    color: #0b407d !important;
    text-decoration: underline !important;
}

.menu-font-child-item{/*h4*/
    font-size: var(--font-size-sm);
    line-height: 1.2;
}


