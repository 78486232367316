.home-banner-bg{
    /*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/general/homePageBannerbackground.jpg");*/
    background-image: url("../assets/img/DL Homepage/dlBanner.jpg");
    background-position: center;
    /*height: 602px;*/
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
}

.banner-text {
    font-size: 48px;
    font-weight: 600;
}


.link-style{
    color: #2376D4 !important;
    text-decoration: underline !important;
}
.linear-gradient-border-top{
    text-align:center;
    border-right: 2px solid transparent;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, #07216E 49.48%);
    border-image-slice: 1;
    width:100%;

}
.linear-gradient-border-bottom{
    text-align:center;
    border-right: 2px solid transparent;
    border-image-source: linear-gradient(180deg, #07216E 49.48%, #FFFFFF 100%);
    border-image-slice: 1;
    width:100%;

}
.linear-gradient-border{
    text-align:center;
    border-bottom: 2px solid transparent;
    border-image-source: linear-gradient(90deg,  #FFFFFF 3%, #07216E 49.48%, #FFFFFF 100%);
    border-image-slice: 1;
    width:100%;

}

.value-proposition-bg {
    background-image: url("../assets/img/DL Homepage/valueProposition.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
}

.gradient-border {
    width: 200px;
    height: 200px;
    border-width: 10px;
    border-style: none none solid none;
    border-image: linear-gradient(180deg, #FFFFFF 0%, #FF9800 49.48%, #FFFFFF 100%);
    border-image-slice: 1;
    /* Ensures the entire border is filled with the gradient */
}

.our-capabilities-panel {
    background-color: #F3F8FD;
    min-height: 300px;
    border-radius: 7px;
    padding: 20px 30px;
}

@media only screen and (max-width: 992px) {
    .home-banner-bg{
        background-image: url("../assets/img/DL Homepage/dlBannerMobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-origin: border-box;
    }
    .banner-text {
        font-size: 40px;
        font-weight: 600;
    }
}
