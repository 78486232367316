.label {
    font-size: var(--font-size-md);
}

.label:hover {
    text-decoration: underline;
}

.accordian-title {
    font-size: var(--font-size-md);
}

.feature-menu {
    font-size: var(--font-size-sm);
}

.use-cases-dropdown button {
    --bs-btn-color: #fff;
    --bs-btn-bg: white !important;
    --bs-btn-border-color: white !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: none !important;
    --bs-btn-hover-border-color: none !important;
}

.use-cases-dropdown .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 7px -4px 6px rgba(0, 0, 0, 0.05) !important;
}

.use-cases-dropdown button {
    --bs-btn-color: #fff;
    --bs-btn-bg: white !important;
    --bs-btn-border-color: white !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: white !important;
    --bs-btn-focus-shadow-rgb: white !important;
    --bs-btn-active-color: white !important;
    --bs-btn-active-bg: white !important;
    --bs-btn-active-border-color: white !important;
    --bs-btn-active-shadow: 0 1px 5px 1px #000000 !important;
    --bs-btn-disabled-color: white !important;
    --bs-btn-disabled-bg: white !important;
    --bs-btn-disabled-border-color: white !important;
}

.use-cases-dropdown .dropdown-toggle::after {
    display: none;
}

.use-cases-dropdown .dropdown-menu {
    --bs-dropdown-min-width: 223px !important;
    --bs-dropdown-padding-y: 0px !important;
    inset: 15px auto auto 0 !important;
    margin-top: -15px !important;
    margin-left: -6px !important;
    --bs-dropdown-border-color: none !important;
}

.use-cases-dropdown .dropdown-item {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: Barlow !important;
    display: block;
    width: 100%;
    padding: 6px 20px 6px 20px !important;
    clear: both;
    color: var(--primary) !important;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.use-cases-dropdown .dropdown-item:hover {
    color: #0b407d !important;
    text-decoration: underline !important;
}

.use-cases-dropdown .dropdown-item:visited {
    color: #0b407d !important;
    text-decoration: underline !important;
}

.usecases-menu-selected {
    color: #0b407d !important;
    text-decoration: underline !important;
}

.feature-menu-selected {
    color: #0b407d !important;
    text-decoration: underline !important;
}

.dropdown-item a:hover {
    color: #0b407d !important;
}
#app-header-nav {
    display: flex;
    gap: 50px;
}
#app-header-nav a{
    text-decoration: none;
    color: var(--black-text);
}
#app-header-nav a:hover{
    border-bottom: 2px solid #0007F3 !important;
}
#app-header-nav a.active{
    /*text-decoration: underline !important;*/
    color: #0007F3 !important;
    font-weight: 500;
    border-bottom: 2px solid #0007F3 !important;
}
.remove-underline{
    text-decoration: none !important;
}
.menu-item-active{
    color: #0007F3 !important;
    font-weight: 500;

}
