.technology-banner-bg {
    background-image: url("../assets/img/Technology partnership/techPartnerBanner.jpg");
    background-position: center;
    height: 441px;
    background-repeat: no-repeat;
    background-origin: border-box;
    display: flex;
    background-size: 100% 100%;
    align-items: center;



}

@media only screen and (max-width: 992px) {
    .technology-banner-bg {
        background-image: url("../assets/img/Technology partnership/techPartnerMobile.jpg");
        height: 441px;
    }
}
