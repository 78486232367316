.innerWindowHeight {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    /*height:  var(--vh, 100vh) ;*/
}
.block-container{
    padding-right: 100px;
    padding-left: 100px;
}

.padding-block-top{
    padding-top: 100px;
}

.padding-block-bottom{
    padding-bottom: 100px;
}

.padding-block-left{
    padding-left: 100px;
}

.padding-block-right{
    padding-right: 100px;
}
.pt-60{
    padding-top: 60px;
}
.pb-60{
    padding-bottom: 60px;
}
.pb-50{
    padding-bottom: 50px;
}
.pt-40{
    padding-top: 40px;
}
.pb-40{
    padding-bottom: 40px;
}
.pt-20{
    padding-top: 20px;
}
.pb-20{
    padding-bottom: 20px;
}
.theme-content-line-height{
    line-height: 24px;
}
.theme-content-md-line-height{
    line-height: 35px;
}
.cursor{
    cursor: pointer;
}

.linear-gradient-background{
    background: linear-gradient(90deg, #1671DD 0%, #071F6B 99%);
}
.top-strip-blue{
    height: 10px;
    background: linear-gradient(90deg, #1671DD 0%, #071F6B 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.border-linear-gradient{
    border-right: 2px solid transparent;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, #07216E 49.48% , #FFFFFF 100%);
    border-image-slice: 1;
    width:100%;

}
/*.border-linear-gradient-bottom{*/
/*    text-align:center;*/
/*    border-bottom: 2px solid transparent;*/
/*    border-image-source: linear-gradient(90deg, #07216E 49.48%, #FFFFFF 100%);*/
/*    border-image-slice: 1;*/
/*    width:100%;*/

/*}*/

.button-style {
    color: #FFFFFF;
    background: linear-gradient(180deg, #1671DD 0%, #1053B3 100%);
    font-weight: 500;
    border: none;
    min-height: 44px;
    border-radius: 7px;
    min-width: 165px;
    padding: 12px 24px;
    height: 48px;
    line-height: 22px;
    opacity: 100%;
    z-index: 1 !important;
}
.button-style-white {
    color: var(--black);
    background: #FFFFFF;
    font-weight: 500;
    border: none;
    min-height: 44px;
    border-radius: 7px;
    min-width: 165px;
    padding: 12px 24px;
    height: 48px;
    line-height: 22px;
    opacity: 100%;
    z-index: 1 !important;
}

@media only screen and (max-width: 1200px) {

    .block-container{
        padding-right: 48px;
        padding-left: 48px;
    }
}

@media only screen and (max-width: 992px) {
    .padding-banner-header-bottom{
        padding-bottom: 40px;
    }

    .padding-section-vertical{
        padding-bottom: 90px;
        padding-top: 90px;
    }

    .padding-section-bottom{
        padding-bottom: 90px;
    }

    .padding-section-header-bottom{
        padding-bottom: 35px;
    }
    .paragraph-padding{
        padding-bottom: 20px;
    }
    .padding-block-top {
        padding-top: 70px;
    }

    .padding-block-bottom {
        padding-bottom: 70px;
    }

    .padding-block-left {
        padding-left: 16px;
    }

    .padding-block-right {
        padding-right: 16px;
    }
    .block-container{
        padding-right: 48px;
        padding-left: 48px;
    }
}


@media only screen and (max-width: 768px) {
    .block-container{
        padding-right: 16px;
        padding-left: 16px;
    }
}
.link-color{
    color:#0073FF;

}

/*.text-underline{*/
/*    text-decoration: underline;*/
/*    color: #FEA133;*/
/*}*/
