.software-banner-bg {
    background-image: url("../assets/img/software development services/customBanner.jpg");
    background-position: center;
    height: 441px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-origin: border-box;
    display: flex;
    align-items: center;

}

.panel-header-text-height {
    min-height: 100px;
}

@media only screen and (max-width: 1493px) {
    .panel-header-text-height {
        min-height: 148px;
    }
}
@media only screen and (max-width: 992px) {
    .software-banner-bg{
        background-image: url("../assets/img/software development services/customBannerMobile.jpg");
    }
}
